.header-profile-user {
  background-color: transparent;
  padding: 0px;
}

.header-item {
  margin-left: 10px;
}

.table-nowrap #IP {
  color: blue !important;
  white-space: nowrap;
}

.subdomainPortScanCardHead {
  display: flex;
  padding: 17px 20px !important;
  justify-content: space-between;
}

.subdomainPortScanCardBody {
  height: 300px !important;
  overflow: auto !important;
}

.mrRight {
  margin-right: 10px !important;
}

/* width */
.subdomainPortScanCardBody::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.subdomainPortScanCardBody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.subdomainPortScanCardBody::-webkit-scrollbar-thumb {
  background: rgb(197, 197, 197);
}

/* Handle on hover */
.subdomainPortScanCardBody::-webkit-scrollbar-thumb:hover {
  background: rgb(202, 202, 202);
}

:-webkit-any(table, thead, tbody, tfoot, tr)>form {
  display: block !important;
}


.addUserForm {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
}

.addUserForm .string,
.addUserForm .addRisk {
  width: 90%;
  margin: 0 15px;
}
.userProfile{
  .auth-content{
    margin-top: 0 !important;
  }
}
#riskColorRed {
  color: red;

}

#setGreen {
  color: green;
}

#setYellow {
  color: yellow;
}

#setRed {
  color: red;
}

#setOrange {
  color: orange;
}

#setGrey {
  color: grey;
}

.ScanProfileHeading {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px !important;
}

// .modal-body {
//   overflow: auto;
//   max-height: 500px;
// }

// .zIndex{
//   z-index: 1000;
// }
.ck.ck-dropdown .ck-dropdown__arrow{
  z-index: 0;
}

.textWhiteCard {
  color: white !important;
}

.upper_div {
  width: 100%;
  height: 450px;
  background-color: #5156be;
  position: relative;
  // display: flex;
  // justify-content: end;

}

.upper_div svg {
  margin-top: 291px;
  width: 100%;
}

.mid_div {
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.lower_div {
  width: 100%;
  // height: 50vh;
  background-color: white;
}

.login_card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.login_card{
  transition: all 0.6s;
  animation: flip 1s ;
  // transform-style: preserve-3d;
}

@keyframes flip {
  0% {
    transform: rotateY(180deg); 
  }
  
}
.login_card_row {
  // margin-top: -330px;
  justify-content: center;
  z-index: 2;
  background-color: #ffffff;
  height: 100%;
}

.login_card_row .card {
  z-index: 2;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 7px;
}

.siteInfo {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  height: 100%;
  .mainHeader {
    display: flex;
    // justify-content: center;
    align-items: center;

    .logo {
      padding-right: 30px;
    }

    .title {
      font-weight: 800;
      font-size: 1.4rem;
    }

    margin-bottom: 10px;
  }

  .header {
    .heading {
      font-weight: 700;
      font-size: 14px;
      color: grey;
      margin-bottom: 5px;
    }

    .title {
      font-size: 15px;
      font-weight: 700;
      color: black;
    }
    margin-bottom: 20px;
  }
}


// transperent loader for start audit loding 

.transparent-loader{
  color: red;
  position: absolute;
  height: 80vh;
  left: 0%;
  width: 98%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #5156be;
  backdrop-filter: blur(5px);
}


.sub-transparent-loader{
  position: absolute;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 2;
  display: flex;
  top: -10%;
  left: 0%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 98%;

  // height: 100vh;
  // left: 5%;
  // width: 94%;
  // z-index: 1000;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // // background-color: #5156be;
  // backdrop-filter: blur(5px);
}
.startAuditPreloader{
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(5px); 
  opacity: 1;
  visibility: visible;
  
  height: 100vh;
  width: 100vw;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
//  backdrop-filter: blur(5px); 
//  position: absolute;
//   z-index: 2;
//   display: flex;
//   height: 77vh;
//   justify-content: center;
//   align-items: center;
//   width: 97%;
}
// spinner 
$color: rgb(0, 92, 132);
.loader-spinner {
  width: 150px;
  height: 150px;
  
  svg {
    width: 90%;
    fill: none;
   } 
}

// .ck-editor{
//   max-height: 200px;
// }
#sidebar-menu {
  position: fixed;
}
.auditSense_logo{
  width: 65%;
  // margin: auto;
  // height: 100%;
}
.auditSense_logo img{
  width: 100%;
  height: 100%;
}

// transperent loader for start audit loding 

.transparent-loader{
  color: red;
  position: absolute;
  height: 100vh;
  left: 5%;
  width: 94%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #5156be;
  backdrop-filter: blur(5px);
}


.sub-transparent-loader{
  position: absolute;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 2;
  display: flex;
  top: -10%;
  left: 0%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 98%;

  // height: 100vh;
  // left: 5%;
  // width: 94%;
  // z-index: 1000;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // // background-color: #5156be;
  // backdrop-filter: blur(5px);
}
.startAuditPreloader{
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(5px); 
  opacity: 1;
  visibility: visible;
  
  height: 100vh;
  width: 100vw;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
//  backdrop-filter: blur(5px); 
//  position: absolute;
//   z-index: 2;
//   display: flex;
//   height: 77vh;
//   justify-content: center;
//   align-items: center;
//   width: 97%;
}
// spinner 
$color: rgb(0, 92, 132);
.loader-spinner {
  width: 150px;
  height: 150px;
  
  svg {
    width: 90%;
    fill: none;
   } 
}

.load {
  transform-origin: 50% 50%;
  stroke-dasharray: 0.7 0.3;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: $color;

  &.one {
    animation: load 1.5s infinite ease-in;
    animation-direction: reverse;
  }
  &.two {
    fill: $color;
  }
  &.three {
    animation: load 1.5s infinite;
  }
}

@keyframes load {
  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 990px) {
  
.searchBar1{
  display: none !important;
}
}
@media screen and (max-width: 576px) {
  .editFooter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-bottom: 10px;
  }
}